import React from 'react';
import WhatsAppLink from './WhatsAppLink';
import SocialContainer from './social/SocialContainer';
import { useBreakpointContext } from './App';

const Footer = () => {

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    return (
        <footer style={{
            color: 'rgb(0, 94, 161)',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
            justifyContent: 'space-between',
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '1em',
            width: '100%',
            marginTop: '70px',
            paddingTop: isMobile ? '20px' : '150px',
            paddingBottom: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '140px' : '50px',
            backgroundColor: 'rgba(0,0,0,0.8)',
            marginBottom: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '58px' : '0px',
        }}>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'center' : 'space-between',
                alignItems: isMobile ? 'center' : 'stretch',
                margin: 0,
                paddingTop: '30px',
                gap: isMobile ? '30px' : '0px',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    justifyContent: 'space-between',
                    gap: '10px',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    paddingLeft: isMobile ? '0px' : '50px',
                    marginBottom: isMobile ? '30px' : '0px',
                    color: 'white',
                    fontSize: '0.85em',
                }}>
                    <h4>Nueva Nordic</h4>
                    <div>St. Petersburg, FL USA</div>
                    <div><WhatsAppLink /></div>
                    <div>
                        <strong>Email:</strong> <a
                            style={{
                                color: 'white',
                                textDecoration: 'none',
                            }}
                            href="mailto:nuevanordic@gmail.com">nuevanordic@gmail.com</a>
                    </div>
                </div>
                {/*<div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '40%',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '50px',
                        }}>*/}
                {/*<form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <input type="text" placeholder="Name" style={{ marginBottom: '1rem', padding: '0.5rem', paddingLeft: '12px', border: '1px solid rgb(0, 94, 161)', borderRadius: '15px', fontFamily: 'inherit', backgroundColor: 'transparent', color: 'rgb(0, 94, 161)', outline: 'none', boxShadow: 'none' }} onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Name'} />
                <input type="email" placeholder="Email" style={{ marginBottom: '1rem', padding: '0.5rem', paddingLeft: '12px', border: '1px solid rgb(0, 94, 161)', borderRadius: '15px', fontFamily: 'inherit', backgroundColor: 'transparent', color: 'rgb(0, 94, 161)', outline: 'none', boxShadow: 'none' }} onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Email'} />
                <textarea rows="3" placeholder="Message" style={{ marginBottom: '1rem', padding: '0.5rem', border: '1px solid rgb(0, 94, 161)', borderRadius: '15px', fontFamily: 'inherit', backgroundColor: 'transparent', color: 'rgb(0, 94, 161)', outline: 'none', resize: 'none', padding: '12px', boxShadow: 'none', height: '159px' }} onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Message'} />
                <button type="submit" style={{ padding: '0.5rem', backgroundColor: 'transparent', color: 'rgb(0, 94, 161)', borderRadius: '15px', fontFamily: 'inherit', cursor: 'pointer', border: '1px solid rgb(0, 94, 161)', boxShadow: 'none' }}>Send Message</button>
          </form>*/}
                {/*</div>*/}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    justifyContent: isMobile ? 'center' : 'flex-end',
                    justifyItems: isMobile ? 'center' : 'flex-end',
                    alignItems: isMobile ? 'center' : 'flex-end',
                    paddingRight: isMobile ? '0px' : '50px',
                }}>
                    <div style={{
                        paddingBottom: '30px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}>
                        <SocialContainer
                            buttons={[
                                {
                                    link: 'https://instagram.com/nuevanordic?igshid=OGQ5ZDc2ODk2ZA==',
                                    color: '#E4405F',
                                    service: 'instagram'
                                },
                                {
                                    link: 'https://pin.it/4v4FOyM',
                                    color: '#BD081C',
                                    service: 'pinterest'
                                },
                                {
                                    link: 'https://www.facebook.com/profile.php?id=100093906185848&mibextid=LQQJ4d',
                                    color: '#4267B2',
                                    service: 'facebook'
                                },
                                {
                                    link: 'https://youtube.com/@NuevaNordic',
                                    color: '#FF0000',
                                    service: 'youtube'
                                },
                            ]}
                        />
                    </div>
                    <div style={{
                        fontSize: '0.85em',
                        color: 'white',
                        textAlign: isMobile ? 'center' : 'right',
                    }}>© 2023 Nueva Nordic. All rights reserved.</div>
                </div>
            </div>


        </footer >
    );
};

export default Footer;
