import React from 'react';
import Icon from './Icon';

const Location = ({ location }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: '8px',
                color: 'black',
                fontWeight: 600,
                fontSize: '0.8em',
                fontFamily: 'Anuphan',
                filter: 'box-shadow(0px 0px 10px 0px rgba(0,0,0,0.5))',
                background: "#FFF9ED",
                padding: '4px 20px',
                transition: 'all 0.3s ease',
                borderBottomRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                marginTop: '-5px',
                //borderRadius: '10px',
            }}
        >
            <Icon name="location" width="20" color="white" />
            {location}
        </div>
    );
};

export default Location;
