import React from 'react';
import Paragraph from './Paragraph';

const TabIcon = ({ icon }) => {
    return (
        <div style={{
            backgroundImage: `url(${icon})`,
            backgroundRepeat: 'no-repeat',

            width: '120px',
            height: '120px',
            backgroundSize: 'contain',
            backgroundPosition: 'left',
            marginBottom: '20px',
        }}></div>
    );
}

export default TabIcon;