import React, { useEffect, useRef, useState } from 'react';
import VrboIcon from './vrbo-logo.png';
import AirbnbIcon from './airbnb-logo.png';
import AirbnbSuperhost from './airbnb_superhost.png';
import VrboPremierhost from './vrbo_premierhost.png';
import ZillowIcon from './zillow-logo.png';  // Placeholder SVG
import HotpadsIcon from './hotpads-logo.png';  // Placeholder SVG
import TruliaIcon from './trulia-logo.png';  // Placeholder SVG
import BookingcomIcon from './bookingcom.png';  // Booking.com logo

const Booking = ({ showBookingOverlay, onClose, propertyId, propertyTitle }) => {
    const drawerRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [links, setLinks] = useState({});

    useEffect(() => {
        if (showBookingOverlay) {
            drawerRef.current.style.transform = 'translateX(0%)';

            let newLinks = {};
            if (propertyTitle === "Dexter's Beach Home") {
                newLinks = {
                    Airbnb: "https://airbnb.com/h/dextersbeachhome",
                    VRBO: "https://www.vrbo.com/861911",
                };
            } else if (propertyTitle === "Coffee Pot Pool House") {
                newLinks = {
                    Airbnb: "https://airbnb.com/h/cpph",
                    VRBO: "https://www.vrbo.com/3430444",
                    Zillow: "https://www.zillow.com/homedetails/125831154_zpid/?view=public",
                    Trulia: "https://www.trulia.com/p/fl/saint-petersburg/325-22nd-ave-ne-saint-petersburg-fl-33704--1150461277",
                    Hotpads: "https://hotpads.com/325-22nd-ave-ne-saint-petersburg-fl-33704-wd398y/pad",
                };
            }
            else if (propertyTitle === "Aldea Dream Home") {
                newLinks = {
                    Airbnb: "https://www.airbnb.com/rooms/820364726514824799?guests=1&adults=1&s=67&unique_share_id=7ea698a9-92ad-4fb8-adfb-0d8240072c03&source_impression_id=p3_1713996418_ROM1CyQP7X7yWHER",
                    VRBO: "https://www.vrbo.com/3249838?dateless=true",
                    Bookingcom: "https://www.booking.com/hotel/mx/aldea-dream-home-two-story-designer-penthouse-tulum.html",
                };
            }
            setLinks(newLinks);
        } else {
            drawerRef.current.style.transform = 'translateX(100%)';
        }
    }, [showBookingOverlay]);

    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(100, 100, 100, 0.5)',
                zIndex: '999999',
                transition: 'all 0.3s ease',
                opacity: showBookingOverlay ? 1 : 0,
            }}
        >
            <div
                ref={drawerRef}
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: '350px',
                    padding: '10px',
                    maxWidth: '600px',
                    height: '100%',
                    backgroundColor: '#FFF9ED',
                    borderLeft: '12px solid rgb(0, 94, 161)',
                    zIndex: 999,
                    transition: 'transform 0.3s ease',
                }}
            >
                <button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        backgroundColor: 'transparent',
                        color: 'black',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        border: 'none',
                        cursor: 'pointer',
                        outline: 'none',
                    }}
                >
                    &times;
                </button>
                <div
                    style={{
                        height: 'calc(100% - 50px)',  // 50px for the bottom bar
                        overflowY: isLoading ? 'hidden' : 'auto',
                    }}
                >
                    <h2 style={{ textAlign: 'center', marginTop: '50px' }}>{propertyTitle}</h2>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <p>Use one of the options below:</p>

                        {Object.entries(links).map(([service, link], index) => {
                            let icon, color;
                            if (service === 'Airbnb') {
                                icon = AirbnbIcon;
                                color = '#FF5A5F';
                            } else if (service === 'VRBO') {
                                icon = VrboIcon;
                                color = '#1C4695';
                            } else if (service === 'Bookingcom') {  // Add Booking.com here
                                icon = BookingcomIcon;
                                color = '#003580';  // Booking.com brand color
                            }

                            return (
                                <a
                                    key={index}
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: color,
                                        color: 'white',
                                        padding: '10px',
                                        margin: '5px',
                                        width: '40%',
                                        borderRadius: '50px',
                                        border: '1px solid rgba(0,0,0,0.3)',
                                        textDecoration: 'none',
                                        transition: 'background-color 0.3s ease',
                                        fontWeight: 'bold',
                                        position: 'relative',  // Needed for the overlay
                                    }}
                                >
                                    <img src={icon} alt={service} style={{ height: '18px', marginRight: '10px', zIndex: 2 }} />
                                    <div  // This is the overlay
                                        onMouseEnter={e => e.target.style.opacity = 0.1}
                                        onMouseLeave={e => e.target.style.opacity = 0}
                                        style={{
                                            position: 'absolute',
                                            borderRadius: '50px',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'black',
                                            opacity: 0,  // Initially transparent
                                            zIndex: 1,
                                        }}
                                    ></div>
                                </a>
                            );
                        })}
                    </div>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <a href="https://booking.hospitable.com/widget/9cda1349-3b41-4771-b20f-44701697526d/992518">
                            Or book directly with us here
                        </a>
                    </div>
                </div>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: '#FFF9ED',
                        padding: '30px 0',
                        height: 'auto',
                    }}
                >
                    <a href="https://www.airbnb.com/users/show/1594180"
                        target="_blank"
                    >
                        <img src={AirbnbSuperhost} alt="Airbnb Superhost" style={{ height: '40px', marginLeft: '15px' }} />
                    </a>
                    <a href={links['VRBO']}>
                        <img src={VrboPremierhost} alt="VRBO Premier Host" style={{ height: '40px', marginRight: '30px' }} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Booking;