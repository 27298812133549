import React, { useState } from 'react';
import Booking from './Booking';
import { useBreakpointContext } from './App';

const FixedBookingButton = ({ property }) => {
    const [showBookingOverlay, setShowBookingOverlay] = useState(false);
    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    return (
        <>
            <div
                onClick={() => property.availability === "Available" ? setShowBookingOverlay(!showBookingOverlay) : null}
                style={{
                    position: isMobile ? 'fixed' : 'fixed',
                    bottom: 'auto',
                    top: isMobile ? '57px' : '88px',
                    left: 'auto',
                    right: isMobile ? 0 : '0px',
                    display: isMobile ? 'block' : 'block',
                    marginTop: isMobile ? '0px' : '0px',
                    paddingLeft: isMobile ? '0px' : '0px',
                    paddingRight: isMobile ? '0px' : '0px',
                    cursor: property.availability === "Available" ? 'pointer' : 'default',
                    zIndex: '100',
                    opacity: 0,
                    animation: 'fadeIn 1s forwards 0.5s, slideUp 0.5s forwards 0.5s',
                }}
            >
                <div style={{
                    backgroundColor: property.availability === "Available" ? '#4BA182' : '#363636',
                    color: '#fff',
                    paddingTop: isMobile ? '20px' : '25px',
                    paddingBottom: isMobile ? '20px' : '25px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    fontSize: '1em',
                    fontWeight: '700',
                    border: 'none',
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.15)',
                }}>
                    {property.availability === "Available" ? (
                        <div>BOOK PROPERTY</div>
                    ) : (
                        <div>Currently Unavailable</div>
                    )}
                </div>
            </div>
            {showBookingOverlay && (
                <Booking
                    showBookingOverlay={showBookingOverlay}
                    onClose={() => setShowBookingOverlay(false)}
                    property={property}
                    propertyTitle={property.name} />
            )}
        </>
    );
};

export default FixedBookingButton;