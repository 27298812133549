import React, { useState } from 'react';
import Modal from 'react-modal';
import { X } from 'react-feather';

const ExternalLinkModal = ({ isOpen, url, onClose, size }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            className=""
            overlayClassName=""
            contentLabel="External URL Modal"
            appendTo={document.body}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    backdropFilter: 'blur(10px)',
                    zIndex: 9999
                },
                content: {
                    position: 'fixed',
                    width: '91.6666666667%',
                    height: '75%',
                    maxHeight: '100vh',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '0.5rem',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                    padding: 0,
                    '@media (min-width: 768px)': {
                        width: '75%'
                    }
                }
            }}
        >
            <div style={{ position: 'relative', height: '100%', overflow: 'hidden', borderRadius: '0.375rem' }}>
                <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
                    <button onClick={onClose} style={{ color: 'black', transition: 'opacity 0.15s ease-in-out' }}>
                        <X size={25} />
                    </button>
                </div>
                <iframe src={url} width="100%" height="100%" title="External URL" />
            </div>
        </Modal>
    );
}

export default ExternalLinkModal;