import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from './Icon';

const moveLeft = keyframes`
  from {
    transform: translateX(0);
  }
`;

const MarqueeContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  padding: 10px 0;
  overflow: hidden;
  cursor: default;
`;

const MarqueeArea = styled.div`
  display: inline-block;
  white-space: nowrap;
  transform: translateX(-${(props) => props.move}px);
  animation: ${moveLeft} ${(props) => props.time}s linear infinite;
  animation-play-state: inherit;
`;

const MarqueeItem = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 0rem;
  font-family: 'Anuphan', sans-serif;
`;

const PropertyNumber = styled.span`
  font-weight: 600;
  color: rgb(0, 94, 161);
`;

const Location = styled.span`
  font-weight: 300;
  color: rgb(0, 94, 161);
`;

const getFillList = (list, copyTimes = 1) => {
    let newList = [];
    for (let i = 0; i < copyTimes; i++) {
        newList.push(...list);
    }
    return newList;
};

const TextSlider = ({ items, time = 15, breakpoint }) => {
    const marqueeContainer = useRef(null);
    const marqueeArea = useRef(null);
    const [moveLeft, setMoveLeft] = useState(0);
    const [showList, setShowList] = useState(items);
    const [realTime, setRealTime] = useState(time);

    useEffect(() => {
        const containerWidth = Math.floor(marqueeContainer.current.offsetWidth);
        const areaWidth = Math.floor(marqueeArea.current.scrollWidth);
        const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));
        const newMoveLeft = areaWidth * Math.floor(copyTimes / 2);
        const newRealTime = time * parseFloat((newMoveLeft / containerWidth).toFixed(2));
        setShowList(getFillList(items, copyTimes));
        setMoveLeft(newMoveLeft);
        setRealTime(newRealTime);
    }, [items]);

    const renderItems = () =>
        showList.map((item, index) => {
            const [propertyNumber, location] = item.split(':');
            return (
                <MarqueeItem key={`${item}-${index}`} style={{
                    fontSize: breakpoint === 'xs' || breakpoint === 'sm' ? '1.7em' : '2.3em',
                }}>
                    <PropertyNumber>{propertyNumber.trim()} ⎯ </PropertyNumber>
                    <Location>{location.trim()}</Location>
                    {index !== showList.length - 1 && (
                        <>
                            <div style={{
                                display: 'inline-block',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '7rem',
                                textAlign: 'center',
                            }}>
                                <Icon name="nn" color="rgb(0, 94, 161)" width="25" />
                            </div>
                        </>
                    )}
                </MarqueeItem>
            );
        });

    return (
        <MarqueeContainer ref={marqueeContainer}>
            <MarqueeArea ref={marqueeArea} move={moveLeft} time={realTime}>
                {renderItems()}
            </MarqueeArea>
        </MarqueeContainer>
    );
};

export default TextSlider;
