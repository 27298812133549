import React, { useState } from 'react';
import logoSvg from './logo.svg'; // Replace this with the path to your logo SVG file
import Booking from './Booking';
import Icon from './Icon';

const Button = () => {
    return (
        <>
            <button
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgb(60, 60, 60)',
                    color: '#FCEAC6',
                    borderRadius: '10px',
                    padding: '15px 20px',
                    fontSize: '0.9em',
                    border: 'none',
                    cursor: 'pointer',
                    outline: 'none',
                    transition: 'background-color 0.3s ease',
                    fontFamily: 'Anuphan',
                    fontWeight: 500,
                    gap: '10px',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056B3')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgb(60, 60, 60)')}
            >
                <Icon name="info" width="10" color="#FCEAC6" />
                More Info
            </button>
        </>
    );
};

export default Button;
