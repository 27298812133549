import React from 'react';
import { useBreakpointContext } from './App';
import { mediaPrefix } from './api/apiService';

const Amenities = ({ incoming }) => {
    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: isMobile ? 'left' : 'center',
                fontSize: '1.2em',
                paddingTop: isMobile ? '50px' : '60px',
                paddingBottom: isMobile ? '50px' : '45px',
                paddingLeft: isMobile ? '40px' : '40px',
                paddingRight: isMobile ? '40px' : '40px',
                backgroundColor: '#F3E0BB',
                color: 'rgb(60,60,60)',
                width: 'auto',
                borderRadius: '15px',
                fontFamily: 'Anuphan',
                fontWeight: 500,
            }}
        >
            <div style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                gap: isMobile ? '50px' : '60px',
                alignItems: isMobile ? 'flex-start' : 'center',
                width: '100%',
            }}>
                {incoming.map((item, index) => (
                    <div key={item.id} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        justifyContent: 'space-between',

                    }}>
                        {item.icon && <img src={mediaPrefix() + item.icon.url} alt="icon" height="50px" />}
                        <p>{item.amenity}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Amenities;
