import React from 'react';

function WiFiConnection() {
    const connectToWiFi = () => {
        const wifiData = "WIFI:S:ADH;T:WPA;P:TulumWeLove-2023;";
        const encodedWifiData = encodeURIComponent(wifiData);
        const wifiUrl = `https://www.nuevanordic.com/linkhub_adh?cmd=${encodedWifiData}`;
        window.open(wifiUrl, "_blank");
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            margin: 0,
            fontFamily: 'Arial, sans-serif'
        }}>
            <div style={{ textAlign: 'center' }}>
                <img src="https://hausebucket-1.fra1.cdn.digitaloceanspaces.com/nuevanordic/media/wifi.svg" width="200" height="200" alt="WiFi Icon" />
                <br />
                <button onClick={connectToWiFi} style={{
                    padding: '10px 20px',
                    fontSize: '16px'
                }}>Connect</button>
                <br />
                <img src="https://hausebucket-1.fra1.cdn.digitaloceanspaces.com/nuevanordic/media/aldea-original-3.svg" width="200" height="200" alt="Aldea Icon" />
            </div>
        </div>
    );
}

export default WiFiConnection;
