import React from 'react';
import Paragraph from './Paragraph';
import { useBreakpointContext } from '../../App';

const TabContent = ({ children }) => {

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    return (
        <div style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
            fontSize: '0.875rem',
            display: isMobile ? 'none' : 'block',
            textAlign: 'left'
        }}
        >
            <Paragraph>{children}</Paragraph>
        </div>
    );
}

export default TabContent;