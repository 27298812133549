import React from 'react';
import { useBreakpointContext } from '../../App';

const TabList = ({ list }) => {

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    console.log("LIST");
    console.log(list);

    const midpoint = Math.ceil(list?.features?.length / 2);
    const firstColumn = list?.features?.slice(0, midpoint);
    const secondColumn = list?.features?.slice(midpoint);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            background: 'rgba(0,0,0,0.05)',
            borderRadius: '15px',
            padding: '2rem',
        }}>
            <span style={{
                textTransform: 'uppercase',
                lineHeight: '1.5',
                letterSpacing: '0.1em',
                fontSize: '1rem'
            }}>{list.name}</span>
            {(list?.description?.length > 0) && (
                <span style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                    textAlign: 'left',
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                    marginTop: '10px'
                }}>
                    {list?.description}
                </span>)}
            {(list?.features?.length > 0) && (
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
                    gridColumnGap: '1.5rem',
                    width: '100%',
                }}>
                    <ul style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        listStyle: 'none',
                        padding: '0',
                        margin: '0',
                        gap: '0.8rem',
                        marginTop: '1rem',
                    }}>
                        {firstColumn?.map((point, index) => (
                            <li
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    columnGap: '0.8rem',
                                    textAlign: 'left',
                                    fontSize: '1.25rem',
                                    lineHeight: '1.5',
                                }}
                                key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="30px">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                </svg>
                                <div style={{ width: '100%' }}>
                                    {point?.title}
                                </div>
                            </li>
                        ))}
                    </ul>
                    <ul style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        listStyle: 'none',
                        padding: '0',
                        margin: '0',
                        gap: '0.8rem',
                        marginTop: '1rem',
                    }}>
                        {secondColumn?.map((point, index) => (
                            <li
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    columnGap: '0.8rem',
                                    textAlign: 'left',
                                    fontSize: '1.25rem',
                                    lineHeight: '1.5',
                                }}
                                key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="30px">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                </svg>
                                <div style={{ width: '100%' }}>
                                    {point?.title}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>)}
        </div>
    );
};

export default TabList;