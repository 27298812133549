import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from './LanguageContext';
import { useBreakpointContext } from './App';

const LanguageSelector = () => {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const languageMap = {
        en: 'English',
        es: 'Español',
    };

    const handleLanguageSelect = (lang) => {
        setCurrentLanguage(lang);
        setShowDropdown(false); // Close dropdown
    };

    return (
        <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }}>
            {isMobile ? (
                <div>
                    <span onClick={() => handleLanguageSelect('en')}>{currentLanguage === 'en' ? <strong>en</strong> : 'en'}</span>{'  '}/{'  '}
                    <span onClick={() => handleLanguageSelect('es')}>{currentLanguage === 'es' ? <strong>es</strong> : 'es'}</span>
                </div>
            ) : (
                <button
                    onClick={toggleDropdown}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    {currentLanguage}{' '}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width="10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
            )}
            <div
                style={{
                    display: showDropdown ? 'flex' : 'none',
                    position: 'absolute',
                    right: 0,
                    backgroundColor: '#ffffff',
                    minWidth: '160px',
                    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                    zIndex: 1,
                    flexDirection: 'column',
                    gap: '15px',
                    padding: '10px',
                    transition: 'opacity 0.4s ease, transform 0.4s ease',
                    opacity: showDropdown ? 1 : 0,
                    transform: showDropdown ? 'translateY(0)' : 'translateY(-20px)',
                }}
            >
                <button onClick={() => handleLanguageSelect('en')}>{languageMap['en']}</button>
                <button onClick={() => handleLanguageSelect('es')}>{languageMap['es']}</button>
            </div>
        </div>
    );
};

export default LanguageSelector;
