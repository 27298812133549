import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ChevronUp, X } from 'react-feather';
import { Link } from 'react-router-dom';
import cpph from './properties/coffeepot/cpph-thumbnail.jpg'
import adh from './properties/aldea/ADH1-thumbnail.jpg'
import dbh from './properties/dexters/dbh-thumbnail.jpg'
import ExternalLinkModal from './ExternalLinkModal';
import { useBreakpointContext } from './App';
import Logo from './assets/logo.svg';
import Hamburger from 'react-hamburger-menu';
import LanguageSelector from './LanguageSelector';
import { useLanguage } from './LanguageContext';
import { fetchProperties, mediaPrefix } from './api/apiService';


const linkStyles = {
    textDecoration: 'none',
    color: 'black',
    fontSize: '24px', // Larger font size for better tap-ability
    display: 'block',
    margin: '10px 0',
    padding: '15px 0', // Increased padding for better tap-ability
    fontWeight: 500,
    fontFamily: 'Anuphan',
    color: '#005EA1',
};


const SubMenuItem = ({ link, title, oneLiner, image, closeModal }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;



    return (
        <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to={link}
            onClick={closeModal} // Close modal when a link is clicked
            style={{
                textDecoration: 'none',
                color: isHovered ? 'rgb(252, 234, 198)' : '#242222',
                boxSizing: 'border-box',
                width: '100%',
                cursor: 'pointer',
                borderRadius: isHovered ? '30px' : '15px',
                height: '140px',
                backgroundColor: isHovered ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,1)',
                transition: 'all 0.5s ease',
                //padding: isHovered ? '10px' : '0px',
                padding: '0px',
                display: 'flex',
                alignItems: 'flex-start',
                overflow: 'hidden',
                scale: isHovered ? '0.95' : '1',
            }}
        >
            <div
                style={{
                    width: '30%',
                    height: '100%',
                    background: `url(${image}) no-repeat center center`,
                    backgroundSize: 'cover',
                }}
            >
            </div>
            <div style={{ paddingLeft: '20px', paddingBottom: '20px', paddingRight: '20px', paddingTop: '15px', width: '70%' }}>
                <div style={{ fontSize: '1.2em', fontWeight: '600', marginBottom: '5px' }}>{title}</div>
                <div style={{ fontSize: '0.9em' }}>{oneLiner}</div>
            </div>
        </Link>
    );
};

const SubMenu = ({ isOpen, closeModal, isMobile2, subMenuRef }) => {
    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;
    const isTablet = breakpoint.isTablet;
    const { currentLanguage } = useLanguage();

    const [incoming, setIncoming] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIncoming(null);
        setLoading(true);
        setError(null);

        const fetchData = async () => {
            try {
                const propertiesData = await fetchProperties({ language: currentLanguage });  // Fetch the specific page based on slug
                setIncoming(propertiesData);
                setLoading(false);
                console.log("SUB MENU");
                console.log(propertiesData);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [currentLanguage]);

    return (
        <div
            ref={subMenuRef}
            style={{
                position: 'fixed',
                top: isMobile ? '57px' : '57px',
                bottom: isMobile ? '0' : 'auto',
                left: isMobile ? '0' : '0',
                right: isMobile ? '0' : '0',
                backgroundColor: 'rgba(240, 240, 240, 1)',
                backdropFilter: isMobile ? 'unset' : 'blur(20px)',
                WebkitBackdropFilter: isMobile ? 'unset' : 'blur(20px)',
                height: isMobile ? 'auto' : 'auto',
                width: isMobile ? 'auto' : 'auto',
                maxWidth: isMobile ? '100vw' : '100%',
                borderRadius: isMobile ? '0px' : '0px',
                zIndex: '1000',
                color: '#242222',
                overflowY: 'auto',
                borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                paddingLeft: isMobile ? '20px' : '70px',
                paddingRight: isMobile ? '20px' : '70px',

                paddingBottom: isMobile ? '20px' : '70px',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: isMobile ? 'flex-start' : 'space-between',
                boxShadow: '0px 20px 20px rgba(0,0,0,0.2)',
                transition: 'all 0.3s ease',
                opacity: isOpen ? '1' : '0',
                //paddingTop: isMobile ? '20px' : '90px',
                paddingTop: isOpen ? '60px' : '0px',
                pointerEvents: isOpen ? 'auto' : 'none',
                flexDirection: 'column',
                gap: '20px',

            }}
        >
            {isMobile && (
                <button
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        top: '30px',
                        right: '20px',
                        backgroundColor: 'transparent',
                        color: 'black',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        border: 'none',
                        cursor: 'pointer',
                        outline: 'none',
                        paddingBottom: '20px',
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width="30">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                </button>)}
            <div style={{
                fontFamily: 'Playfair Display', color: "rgba(0,0,0,0.8)",
                fontSize: isMobile ? '1.5em' : '2.2em',
                textAlign: 'left',
                width: '100%',
                maxWidth: '1400px',
                marginBottom: '15px',
            }}>
                Explore our properties
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '1fr' : isTablet ? '1fr 1fr' : '1fr 1fr 1fr',
                    gridGap: isMobile ? '10px' : '20px',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    maxWidth: '1400px',
                }}
            >
                {loading && <div>Loading...</div>}
                {error && <div>{error}</div>}
                {incoming && incoming.map((property) => (
                    <SubMenuItem link={`/properties/${property.slug}`} title={property.name} oneLiner={property.tagline} image={mediaPrefix() + property?.thumbnail?.url} closeModal={closeModal} />
                ))}

                {/*<SubMenuItem link="/dexters-beach-home" title="Dexter's Beach Home" oneLiner="An idyllic beach retreat" image={dbh} closeModal={closeModal} />
                <SubMenuItem link="/coffee-pot-pool-house" title="Coffee Pot Pool House" oneLiner="Built in 2022, this brand new custom home is the height of luxury for the refined family or friends wanting the best of life." image={cpph} closeModal={closeModal} />
                <SubMenuItem link="/aldea-dream-home" title="Aldea Dream Home" oneLiner="Luxury in the heart of the city" image={adh} closeModal={closeModal} />*/}
            </div>
        </div>
    );
};


const Navbar = ({ transparentReverse = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTallyOpen, setIsTallyOpen] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const navRef = useRef(null);
    const subMenuRef = useRef(null);
    const tallyUrl = "https://tally.so/r/mRdb7d";
    const [mobileOpen, setMobileOpen] = useState(false);

    const { currentLanguage } = useLanguage();

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const handleClick = () => {
        setMobileOpen(!mobileOpen);
    }

    // Handle scroll position
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Handle clicking outside the submenu
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                navRef.current &&
                !navRef.current.contains(e.target) &&
                subMenuRef.current &&
                !subMenuRef.current.contains(e.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const backgroundColor = scrollY === 0 ? '#fdfaf3' : 'rgba(255, 255, 255, 0.9)';
    const textColor = transparentReverse && scrollY <= 1 ? '#242222' : (scrollY === 0 ? 'white' : '#242222');

    return (
        <>
            <nav
                ref={navRef}
                style={{
                    position: 'fixed',
                    backgroundColor: mobileOpen ? 'rgba(240,240,240,1)' : backgroundColor,
                    backdropFilter: mobileOpen || scrollY === 0 ? 'unset' : 'blur(15px)',
                    WebkitBackdropFilter: mobileOpen || scrollY === 0 ? 'unset' : 'blur(15px)',
                    zIndex: 999,
                    fontSize: '0.9em',
                    width: isMobile ? 'auto' : 'auto',
                    userSelect: 'none',
                    left: isMobile ? '0' : '0',
                    right: isMobile ? '0' : '0',
                    top: 0,
                }}
            >
                <div style={{
                    borderBottom: scrollY === 0 ? 'none' : isMobile ? 'none' : '0.5px solid rgba(0,0,0,0.1)',
                    height: '57px',
                    paddingLeft: isMobile ? '15px' : '45px',
                    paddingRight: isMobile ? '15px' : '45px',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    color: textColor,
                }}>
                    <Link to="/home" style={{ marginTop: '3px' }}>
                        <img src={Logo} />
                    </Link>
                    {!isMobile && (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    listStyleType: 'none',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: isMobile ? '30px' : '40px',
                                    fontWeight: 600,
                                    fonaFamily: 'Anuphan',
                                }}
                            >
                                {/*<div style={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '-15px',
                    color: 'rgb(255, 249, 237)',
                }}><Logo width="25" color="rgb(255, 255, 255)" /></div>*/}
                                <Link to="/home" style={{ textDecoration: 'none', color: '#242222', transition: 'color 0.3s ease' }}>
                                    {currentLanguage === 'en' ? 'Home' : 'Inicio'}
                                </Link>
                                <div style={{ position: 'relative' }}>
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsOpen(!isOpen);
                                        }}
                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#242222' }}
                                    >
                                        <ChevronUp
                                            size={15}
                                            style={{ marginRight: '5px', transition: 'transform 0.3s ease', transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)', color: '#242222' }}
                                        />
                                        {currentLanguage === 'en' ? 'Properties' : 'Propiedades'}
                                    </div>
                                </div>
                                <Link to="/about" style={{ textDecoration: 'none', color: '#242222', transition: 'color 0.3s ease' }}>
                                    {currentLanguage === 'en' ? 'About' : 'Acerca de'}
                                </Link>
                                <Link to="/contact" style={{ textDecoration: 'none', color: '#242222', transition: 'color 0.3s ease' }}>
                                    {currentLanguage === 'en' ? 'Contact' : 'Contacto'}
                                </Link>
                                <LanguageSelector />
                                {/*<Link to="/contact" style={{ textDecoration: 'none', color: 'rgb(255, 255, 255)', transition: 'color 0.3s ease' }}>Contact</Link>*/}
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <>
                            {(transparentReverse === true ? (
                                <div style={{
                                    paddingRight: '15px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Hamburger
                                        mobileOpen={mobileOpen}
                                        menuClicked={() => setMobileOpen(!mobileOpen)}
                                        width={18}
                                        height={15}
                                        strokeWidth={1}
                                        rotate={0}
                                        color='white'
                                        borderRadius={0}
                                        animationDuration={0.5}
                                    />
                                </div>
                            ) : (
                                <div style={{
                                    paddingRight: '15px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}>
                                    {!mobileOpen && (
                                        <Hamburger
                                            mobileOpen={mobileOpen}
                                            menuClicked={() => setMobileOpen(!mobileOpen)}
                                            width={18}
                                            height={15}
                                            strokeWidth={1}
                                            rotate={0}
                                            color='black'
                                            borderRadius={0}
                                            animationDuration={0.5}
                                        />
                                    )}
                                </div>
                            ))}

                        </>
                    )}
                </div>
                {isMobile && (
                    <div style={{
                        transition: '0.6s ease-in-out', // Add transition to height
                        transitionProperty: mobileOpen ? 'height, background-color, padding' : 'height, background-color, padding, margin',
                        height: mobileOpen ? '100vh' : '0vh', // Change height based on isOpen
                        overflow: 'hidden', // Hide content that overflows

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: '0px',
                        paddingTop: mobileOpen ? '130px' : '0px', // Add padding to top when navbar is open
                        backgroundColor: mobileOpen ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',
                        backdropFilter: isMobile ? 'auto' : 'blur(10px)',
                        webkitBackdropFilter: isMobile ? 'auto' : 'blur(10px)',
                        marginTop: mobileOpen ? '0px' : '0px', // Move navbar content off screen when closed
                        paddingLeft: '30px',
                        position: 'relative',
                    }}>
                        {mobileOpen && (
                            <button
                                onClick={handleClick}
                                style={{
                                    position: 'absolute',
                                    top: '30px',
                                    right: '20px',
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    border: 'none',
                                    cursor: 'pointer',
                                    outline: 'none',
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#000000" width="30">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>)}
                        <div
                            onClick={handleClick}
                            style={{
                                position: 'absolute',
                                top: '40px',
                                left: '30px',
                                backgroundColor: 'transparent',
                                opacity: mobileOpen ? '1' : '0',
                                transition: 'opacity 0.4s ease',
                                transitionDelay: '0.2s',
                            }}
                        >
                            <LanguageSelector />
                        </div>
                        <Link to="/home" onClick={handleClick} style={linkStyles}>Home</Link>
                        <Link onClick={(e) => {
                            e.stopPropagation(); // This line prevents the event from propagating up to the document
                            setIsOpen(!isOpen);
                        }}
                            style={linkStyles}>Properties</Link>
                        <Link to="/about" onClick={handleClick} style={linkStyles}>About</Link>
                        <Link to="/contact" onClick={handleClick} style={linkStyles}>Contact</Link>

                    </div>
                )}
            </nav>
            <SubMenu
                isOpen={isOpen}
                closeModal={() => {
                    setIsOpen(false);
                    if (isMobile) {
                        handleClick();
                    }
                }
                }
                isMobile={isMobile}
                subMenuRef={subMenuRef}
            />
            <ExternalLinkModal
                isOpen={isTallyOpen}
                onClose={() => setIsTallyOpen(false)}
                url={tallyUrl}
            />
        </>
    );
};

export default Navbar;