import React from 'react';
import { useState } from 'react';
import { useBreakpointContext } from '../../App';

const TabHeader = ({ key, pre, title, onClick, active }) => {
    const [hover, setHover] = useState(false);

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
            style={{
                transition: 'all ease 0.8s',
                boxShadow: hover && !active ? 'inset 100vw 0 0 0 rgb(252, 234, 198)' : 'inset 0 0 0 0 rgb(252, 234, 198)',
                paddingLeft: hover ? '2rem' : '0',
                fontSize: '1.125rem',
                paddingTop: '2rem',
                paddingBottom: '2rem',
                fontWeight: '500',
                paddingLeft: '0',
                paddingRight: '0',
                cursor: 'pointer',
                borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
                alignItems: 'flex-start',
                textAlign: 'left'
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: '0.75rem'
            }}>
                <div
                    style={{
                        backgroundColor: active ? 'rgb(252, 234, 198)' : 'transparent',
                        color: active ? 'rgb(0, 94, 161)' : 'rgb(60, 60, 60)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.25rem',
                        padding: '0.75rem',
                        borderRadius: '50%',
                        width: '3rem',
                        height: '3rem',
                        fontWeight: 'bold'
                    }}
                >
                    {pre}
                </div>
                <p style={{
                    color: active ? 'rgb(0, 94, 161)' : 'rgb(60, 60, 60)',
                    fontSize: isMobile ? '1.1em' : '1.25em',
                    fontWeight: 'normal'
                }}>{title}</p>
            </div>
        </div>
    );
};

export default TabHeader;