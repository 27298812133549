import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useBreakpointContext } from './App';
import { mediaPrefix } from './api/apiService';
import ReactDOM from 'react-dom';

// Modal settings
Modal.setAppElement('#root');

const modalStyles = {
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        inset: '0',
        background: 'rgba(0, 0, 0, 0.8)',
        border: 'none',
        padding: '0',
        overflow: 'auto',
    },
    overlay: {
        zIndex: 1000,
    },
};

const getYoutubeThumbnailUrl = (youtubeId) => {
    return `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
};

const MediaExplorer = ({ videos, images }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [activeVideo, setActiveVideo] = useState('');

    const openVideoModal = (index, type) => {
        if (type === 'video') {
            setActiveVideo(videos[index].youtubeId);
        }
        setVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        setActiveVideo('');
    };

    const currentImage = images[currentImageIndex];

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const handleArrowClick = (direction) => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex + direction;
            if (newIndex < 0) {
                return images.length - 1;
            }
            if (newIndex >= images.length) {
                return 0;
            }
            return newIndex;
        });
    };

    const openModal = (imageIndex) => {
        setCurrentImageIndex(imageIndex);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (modalIsOpen) {
                if (event.key === 'ArrowRight') {
                    handleArrowClick(1);
                } else if (event.key === 'ArrowLeft') {
                    handleArrowClick(-1);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [modalIsOpen, handleArrowClick]);

    const iframeRef = useRef(null);

    const resizeIframe = () => {
        if (iframeRef.current) {
            const width = iframeRef.current.offsetWidth;
            const height = (width * 9) / 16;
            iframeRef.current.style.height = `${height}px`;
        }
    };

    useEffect(() => {
        // Attach the event listener for window resize
        window.addEventListener('resize', resizeIframe);

        return () => {
            window.removeEventListener('resize', resizeIframe);
        };
    }, []);

    return (
        <>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 2fr))', gap: isMobile ? '10px' : '20px' }}>
                {videos?.length > 0 && videos?.map((video, index) => (
                    <div key={video.id} onClick={() => openVideoModal(index, 'video')}
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            minHeight: isMobile ? '260px' : '350px',
                            gridColumn: 'span 2',
                            overflow: 'hidden',
                            borderRadius: '15px'
                        }}>
                        <img src={getYoutubeThumbnailUrl(video.youtubeId)} alt="video thumbnail" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', filter: 'saturate(120%) contrast(120%)' }} />

                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: 'rgba(0,0,0,0.65)',
                        }}></div>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" width="70px">
                                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                ))}
                {images.map((image, index) => (
                    <div
                        key={index}
                        onClick={() => openModal(index)}
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            minHeight: isMobile ? '220px' : '350px',
                            borderRadius: '15px'
                        }}
                    >
                        <img src={mediaPrefix() + image?.photo?.url} alt={image?.photo?.alt} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                    </div>
                ))}

                {isVideoModalOpen && ReactDOM.createPortal(
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={closeVideoModal}>
                        <div style={{ width: '80%', margin: 'auto' }}>
                            <iframe
                                ref={iframeRef}
                                width="100%"
                                onLoad={resizeIframe}  // resize the iframe when its content is fully loaded
                                src={`https://www.youtube.com/embed/${activeVideo}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>,
                    document.getElementById('modal-root')
                )}

                <AnimatePresence>
                    {modalIsOpen && (
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={modalStyles}
                            contentLabel="Image Modal"
                        >
                            <button onClick={closeModal} className="CloseButton" style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                background: 'rgba(255, 255, 255, 1)',
                                border: 'none',
                                borderRadius: '50%',
                                padding: '10px',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: '24px', height: '24px' }}>
                                    <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <button onClick={() => handleArrowClick(-1)} className="ArrowButton" style={{
                                position: 'absolute',
                                left: '20px',
                                top: '50%',
                                background: 'rgba(255, 255, 255, 1)',
                                border: 'none',
                                borderRadius: '50%',
                                padding: '10px',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: '24px', height: '24px' }}>
                                    <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </button>

                            <button onClick={() => handleArrowClick(1)} className="ArrowButton" style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                                background: 'rgba(255, 255, 255, 1)',
                                border: 'none',
                                borderRadius: '50%',
                                padding: '10px',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: '24px', height: '24px' }}>
                                    <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                            <img src={mediaPrefix() + images[currentImageIndex]?.photo?.url} alt="Full size view" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                        </Modal>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};

export default MediaExplorer;