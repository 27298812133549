export const API_CONFIG = {
    BASE_URL: 'https://nn.hause.host/api',
    //BASE_URL: 'http://localhost:3000/api',
    HEADERS: {
        'Content-Type': 'application/json',
    },
    ENDPOINTS: {
        PROPERTIES: '/properties',
        POSTS: '/posts',
        PAGES: '/pages',
        AUTHORS: '/authors',
    }
};