import React, { useState, useEffect } from 'react';
import TabHeader from './TabHeader';
import TabContent from './TabContent';
import TabList from './TabList';
import TabIcon from './TabIcon';
import { motion } from "framer-motion";
import { useBreakpointContext } from '../../App';
import { mediaPrefix } from '../../api/apiService';
import ReactDOM from 'react-dom';

const TabBar = ({ spaceGroups }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [openTab, setOpenTab] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const handleOverlayClose = () => {
        setShowOverlay(false);
    };

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                position: 'relative',
                gap: '100px'
            }}>
                <div style={{
                    width: '100%',
                    width: isMobile ? '100%' : '33.33%'
                }}>
                    {spaceGroups?.map((tab, index) => (
                        <TabHeader
                            key={index}
                            pre={index + 1}
                            title={tab.title}
                            active={index === activeTab}
                            onClick={() => {
                                setActiveTab(index);
                                setShowOverlay(true);
                            }}
                        />
                    ))}
                </div>

                {(!isMobile && (
                    <motion.div
                        key={activeTab}
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.4 }}
                        style={{
                            display: 'block',
                            width: '66.67%',
                        }}
                    >
                        <TabIcon icon={mediaPrefix() + spaceGroups[activeTab]?.thumbnail?.url} />
                        <TabContent>{spaceGroups[activeTab]?.intro}</TabContent>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                        }}>
                            {spaceGroups[activeTab]?.spaces?.map((space, index) => (
                                <TabList key={index} list={space} />
                            ))}
                        </div>
                    </motion.div>))}

                {isMobile && showOverlay && ReactDOM.createPortal(
                    <motion.div
                        key={activeTab}
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.4 }}
                        style={{
                            position: 'fixed',
                            zIndex: '9999',
                            backgroundColor: 'white',
                            padding: '2rem',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            overflow: 'scroll',
                        }}>
                        <div style={{
                            position: 'fixed',
                            top: '0',
                            right: '0',
                            padding: '2rem',
                            cursor: 'pointer',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            color: 'rgb(30,30,30)',
                        }} onClick={handleOverlayClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="50px">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <TabIcon icon={mediaPrefix() + spaceGroups[activeTab]?.thumbnail?.url} />
                        <TabContent>{spaceGroups[activeTab]?.intro}</TabContent>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            width: '100%',
                        }}>
                            {spaceGroups[activeTab]?.spaces?.map((space, index) => (
                                <TabList key={index} list={space} />
                            ))}
                        </div>
                    </motion.div>,
                    document.getElementById('modal-root')
                )}
            </div>
        </div>
    );
};

export default TabBar;