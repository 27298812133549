import React from 'react';
import SocialButton from './SocialButton';

const SocialContainer = ({ buttons }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {buttons.map((button, index) => (
                <SocialButton key={index} {...button} />
            ))}
        </div>
    );
};

export default SocialContainer;
