import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube, faPinterestP } from '@fortawesome/free-brands-svg-icons';

const getIcon = (name) => {
    switch (name.toLowerCase()) {
        case 'instagram':
            return faInstagram;
        case 'facebook':
            return faFacebookF;
        case 'youtube':
            return faYoutube;
        case 'pinterest':
            return faPinterestP;
        default:
            return null;
    }
}

const SocialButton = ({ link, color, service }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: color,
            margin: '5px',
            textDecoration: 'none',
            transition: 'transform 0.3s ease-in-out'
        }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseOut={(e) => e.currentTarget.style.transform = ''}
        >
            <FontAwesomeIcon icon={getIcon(service)} color="white" />
        </a>
    );
};

export default SocialButton;