import React from 'react';
import VideoPlayer from './VideoPlayer';
import nnWhite from './nuevanordic-white.png';
import { Link } from 'react-router-dom';
import { mediaPrefix } from './api/apiService';
import { useBreakpointContext } from './App';

const PropertyCover = ({ property, incoming }) => {
    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    return (
        <div style={{
            height: isMobile ? '60vh' : property.clips ? '75vh' : '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            color: '#fff',
            backgroundColor: 'rgba(0,0,0,0.9)',
            position: 'relative',
            padding: 0,
            marginLeft: isMobile ? 0 : '40px',
            marginRight: isMobile ? 0 : '40px',
            borderRadius: isMobile ? '0px' : '15px',
            overflow: 'hidden',
        }}>
            {incoming?.videos?.length > 0 && (
                <>
                    <VideoPlayer clips={incoming?.videos.map(clip => ({ src: mediaPrefix() + clip.video.url }))} />
                </>
            )}
            {!property.clips && (
                <>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        position: 'absolute',
                    }}
                    ></div>
                    <img src={mediaPrefix() + incoming?.thumbnail?.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt={property.name} />
                </>

            )}

            <img src={mediaPrefix() + incoming?.logo?.url}
                style={{
                    width: property.clips ? '330px' : isMobile ? '200px' : '250px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    mixBlendMode: property.clips ? 'difference' : 'normal',
                    //filter: 'contrast(500%)'
                }} />
            {/*<h1 style={{
                marginBottom: '0px',
            }}>{property.name}</h1>
        <h3>{property.location}</h3>*/}
        </div>
    )
}

export default PropertyCover;