import React from 'react';
import { useBreakpointContext } from './App';
import { mediaPrefix } from './api/apiService';

const Promotion = ({ block }) => {
    // Destructure the block data
    const { title, description, color, photograph, reversed } = block;

    // You would get the breakpoint from your context or props
    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const textWithBreaks = description?.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <>
            {str}
            <br />
        </>
    );

    return (
        <section
            style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : reversed ? 'row-reverse' : 'row',
                minHeight: '50vh',
                marginTop: isMobile ? '20px' : '30px',
                backgroundColor:
                    color === "cobalt" ? '#005EA1' :
                        color === "neutral" ? '#ECECEC' : '#ffffff',
                marginLeft: isMobile ? '10px' : '40px',
                marginRight: isMobile ? '10px' : '40px',
                borderRadius: '15px',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    background: `url(${mediaPrefix() + photograph.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: isMobile ? 'auto' : '50%',
                    minHeight: '400px',
                }}
            ></div>
            <div
                style={{
                    width: isMobile ? 'auto' : '50%',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTopRightRadius: isMobile ? '0px' : '20px',
                    borderBottomLeftRadius: isMobile ? '20px' : '0px',
                    borderBottomRightRadius: '20px',
                    textAlign: 'center',
                    fontSize: '1.05em',
                }}
            >
                <div style={{
                    fontFamily: 'Playfair Display',
                    color: color === "cobalt" ? '#FCEAC6' :
                        color === "neutral" ? 'rgb(36, 34, 34)' : '#ffffff',
                    fontSize: isMobile ? '2em' : '2.6em',
                    textAlign: 'center',
                    maxWidth: isMobile ? 'auto' : '70%',
                    marginBottom: '15px',
                    paddingLeft: isMobile ? '30px' : '50px',
                    paddingRight: isMobile ? '30px' : '50px',
                    paddingTop: isMobile ? '30px' : '50px',
                }}>
                    {title}
                </div>
                <div
                    style={{
                        marginTop: isMobile ? '0px' : '20px',
                        paddingLeft: isMobile ? '30px' : '50px',
                        paddingRight: isMobile ? '30px' : '50px',
                        paddingBottom: isMobile ? '30px' : '50px',

                        lineHeight: '1.5em',
                        fontSize: '0.95em',
                        color: color === "cobalt" ? '#FCEAC6' :
                            color === "neutral" ? 'rgb(36, 34, 34)' : '#ffffff',
                    }}
                >{textWithBreaks}</div>
            </div>
        </section>
    );
};

export default Promotion;
