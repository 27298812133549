import { API_CONFIG } from './apiConfig';

export const mediaPrefix = () => {
    return 'https://hausebucket-1.fra1.cdn.digitaloceanspaces.com/nuevanordic';
};

export const fetchPosts = async (isPreview = false) => {
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.POSTS}${draftQuery}`, fetchOptions);
        const data = await response.json();
        console.log(data.docs);
        console.log("STORIES");
        return data.docs;
    } catch (error) {
        console.error("Error fetching articles:", error);
        throw error;
    }
};

export const getRandomPosts = async (max = 10, exclude) => {
    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.POSTS}`, fetchOptions);
        const data = await response.json();

        // Filter out the post to exclude
        const filteredData = data.docs.filter(post => post.id !== exclude);

        // Randomize the array
        const shuffled = filteredData.sort(() => 0.5 - Math.random());

        // Get 'max' items
        const randomPosts = shuffled.slice(0, max);

        return randomPosts;
    } catch (error) {
        console.error("Error fetching random posts:", error);
        throw error;
    }
};


export const fetchPost = async (slug, isPreview = false) => {
    let query = `?where[slug][equals]=${slug}&locale=en`;

    if (isPreview) {
        query += '&draft=true';
    } else {
        query += '&draft=false';
    }

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.POSTS}${query}`, fetchOptions);

        const data = await response.json();
        console.log(data);
        console.log("POST BY SLUG");

        return data.docs[0];
    } catch (error) {
        console.error(`Error fetching post with slug ${slug}:`, error);
        throw error;
    }
};

export const fetchAuthor = async (uuid, isPreview = false) => {
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}/authors/${uuid}${draftQuery}`, fetchOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching author:", error);
        throw error;
    }
};

export const fetchPages = async (isPreview = false) => {
    const draftQuery = isPreview ? '?locale=en&draft=true' : '?locale=en&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PAGES}${draftQuery}`, fetchOptions);
        const data = await response.json();
        console.log(data.docs);
        return data.docs;
    } catch (error) {
        console.error("Error fetching pages:", error);
        throw error;
    }
};

export const fetchPage = async (slug, isPreview = false, currentLanguage = 'en') => {
    const draftQuery = isPreview ? '&locale=' + currentLanguage + '&draft=true' : '&locale=' + currentLanguage + '&draft=false';

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PAGES}?where[slug][equals]=${slug}${draftQuery}`, fetchOptions);
        const data = await response.json();

        // Assuming the response returns an array, we'll return the first item for a specific slug
        console.log(data.docs[0]);
        return data.docs[0];
    } catch (error) {
        console.error(`Error fetching page with slug ${slug}:`, error);
        throw error;
    }
};


export const fetchProperties = async ({ isPreview = false, language = 'en', depth = 10 } = {}) => {
    const draftQuery = `&locale=${language}&draft=${isPreview ? 'true' : 'false'}`;
    const depthQuery = `&depth=${depth}`;

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    // Add credentials only if isPreview is true
    if (isPreview) {
        //fetchOptions.credentials = 'include';
    }

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PROPERTIES}?where[visibility][equals]=Shown${draftQuery}${depthQuery}`, fetchOptions);
        const data = await response.json();
        console.log(data.docs);  // Assuming the data structure is similar to other endpoints
        return data.docs;
    } catch (error) {
        console.error("Error fetching properties:", error);
        throw error;
    }
};

export const fetchProperty = async ({ id, isPreview = false, language = 'en', depth = 10 } = {}) => {
    const draftQuery = `&locale=${language}&draft=${isPreview ? 'true' : 'false'}`;
    const depthQuery = `&depth=${depth}`;

    const fetchOptions = {
        method: 'GET',
        headers: API_CONFIG.HEADERS,
    };

    try {
        const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PROPERTIES}?where[slug][equals]=${id}${draftQuery}${depthQuery}&where[visibility][equals]=Shown`, fetchOptions);
        const data = await response.json();

        // Assuming the response returns an array, we'll return the first item for a specific slug
        console.log(data);
        return data.docs[0];
    }
    catch (error) {
        console.error(`Error fetching property with slug ${id}:`, error);
        throw error;
    }
};