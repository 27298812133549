import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import React, { useState, useEffect, createContext, useContext } from 'react';
import Home from './Home';
import About from './About';
import PropertyView from './PropertyView';
import ReactGA from 'react-ga';
import Page from './Page';
import Navbar from './Navbar';
import { LanguageProvider } from './LanguageContext';
import WiFiConnection from './WiFiConnection';

const TRACKING_ID = "G-S35ESS377D"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const defaultBreakpoints = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1600,
};

const BreakpointContext = createContext();
export const PreviewContext = createContext();

const useBreakpoint = (breakpoints = defaultBreakpoints, containerWidth = null) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState('');

  const calculateCurrentBreakpoint = () => {
    const width = containerWidth !== null ? containerWidth : window.innerWidth;
    const matchingBreakpoints = Object.entries(breakpoints).filter(
      ([, breakpointWidth]) => width >= breakpointWidth
    );

    if (matchingBreakpoints.length) {
      const [newBreakpoint] = matchingBreakpoints.slice(-1)[0];
      setCurrentBreakpoint(newBreakpoint);
    } else {
      setCurrentBreakpoint('unknown');
    }
  };

  useEffect(() => {
    calculateCurrentBreakpoint();

    const handleResize = () => {
      calculateCurrentBreakpoint();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerWidth]);

  return {
    isMobile: currentBreakpoint === 'xs' || currentBreakpoint === 'sm',
    isTablet: currentBreakpoint === 'md',
    isDesktop: currentBreakpoint === 'lg' || currentBreakpoint === 'xl',
  };
};

function useScrollToTopOnChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

function ScrollToTop() {
  useScrollToTopOnChange();
  return null;
}

const routesArray = [
  { path: "/:slug", element: <Page /> },
  //{ path: "/stories/:slug", element: <Article /> },
  //{ path: "/about", element: <About /> },
  { path: "/", element: <Page slug="home" /> },
  { path: "/properties/:slug", element: <PropertyView /> },
  { path: "/dexters-beach-home", element: <PropertyView /> },
  { path: "/qr_adh", element: <WiFiConnection /> }
];

function PreviewSetter() {
  const { setIsPreview } = useContext(PreviewContext);

  useEffect(() => {
    setIsPreview(true);
    return () => setIsPreview(false); // Reset on unmount
  }, [setIsPreview]);

  return null;
}

function App() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const breakpoint = useBreakpoint();
  const [isPreview, setIsPreview] = useState(false);


  return (
    <LanguageProvider>
      <PreviewContext.Provider value={{ isPreview, setIsPreview }}>
        <BreakpointContext.Provider value={breakpoint}>
          <div className="App">
            <Router>
              <ScrollToTop />
              <Navbar />
              <Routes>
                <Route
                  path="/preview/*"
                  element={
                    <React.Fragment>
                      <PreviewSetter />
                      <Routes>
                        {routesArray.map((route, index) => (
                          <Route key={index} path={route.path} element={route.element} />
                        ))}
                      </Routes>
                    </React.Fragment>
                  }
                />
                {routesArray.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Routes>
            </Router>
          </div>
        </BreakpointContext.Provider>
      </PreviewContext.Provider>
    </LanguageProvider>
  );
}

export default App;
export const useBreakpointContext = () => useContext(BreakpointContext);