import React from 'react';
import { useBreakpointContext } from './App';
import { mediaPrefix } from './api/apiService';

const ContactCard = ({ block }) => {
    // Destructure the block data
    const { icon, title, phone, whatsapp, email, address } = block;

    // You would get the breakpoint from your context or props
    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const InfoSection = ({ title, value, url }) => {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                borderTop: '0.5px solid rgb(252, 234, 198)',
                paddingTop: '30px',
                paddingBottom: '30px',
            }}>
                <div style={{
                    fontFamily: 'Anuphan',
                    fontWeight: 100,
                    display: 'table',
                    fontSize: isMobile ? '1.7em' : '3em',
                    marginTop: '10px',
                    marginBottom: '10px',
                    textTransform: 'uppercase',
                }}>
                    {title}
                </div>
                <div style={{
                    fontFamily: 'Anuphan',
                    fontWeight: 500,
                    display: 'table',
                    fontSize: isMobile ? '1.7em' : '3em',
                    marginTop: '0px',
                    marginBottom: '10px',
                    textTransform: 'uppercase',
                }}>
                    {url ? <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>{value}</a> : value}
                </div>
            </div>
        );
    };

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <section
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    marginTop: isMobile ? '0px' : '50px',
                    marginLeft: isMobile ? '10px' : '40px',
                    marginRight: isMobile ? '10px' : '40px',
                    borderRadius: isMobile ? '0px' : '15px',
                    overflow: 'hidden',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    maxWidth: '1400px',
                    width: isMobile ? '100%' : 'auto',
                }}
            >
                <div
                    style={{
                        width: isMobile ? 'auto' : '100%',
                        color: 'rgb(252, 234, 198)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        fontSize: '1.05em',
                        paddingLeft: isMobile ? '20px' : '120px',
                        paddingRight: isMobile ? '20px' : '120px',
                        paddingTop: isMobile ? '70px' : '90px',
                        paddingBottom: isMobile ? '40px' : '60px',
                    }}
                >
                    <img src={mediaPrefix() + icon.url} alt="icon" height="60px" style={{ marginBottom: '40px' }} />
                    {/*<h1 style={{
                        fontFamily: 'Anuphan',
                        fontWeight: 900,
                        fontSize: '1.7em',
                        marginTop: '10px',
                        textTransform: 'uppercase',
                        letterSpacing: '3px',
                    }}>{title}</h1>*/}
                    <InfoSection title="Email" value={email} url={`mailto:${email}`} />
                    <InfoSection title="Phone" value={phone} url={`tel:${phone}`} />
                    <InfoSection value="WhatsApp" url={`${whatsapp}`} />
                    <InfoSection title={address} />
                </div>
            </section>
        </div>
    );
};

export default ContactCard;
