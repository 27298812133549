import React from 'react';

const Paragraph = ({ children }) => {
    return (
        <p style={{
            fontFamily: 'Anuphan',
            fontSize: '1.5rem',
            fontWeight: 'normal',
            lineHeight: '1.5',
            whiteSpace: 'pre-line',
            marginBottom: '1rem'
        }}
        >
            {children}
        </p>
    );
}

export default Paragraph;