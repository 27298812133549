import React from 'react';
import { Swiper as SwiperLib, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const Swiper = ({ data }) => {
    return (
        <div className="swiper-container">
            <SwiperLib
                direction="vertical"
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                effect="slide"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="card-site-anime__slide">
                            <div className="card-site-anime__label is-text-anime">
                                <span>{item.header}</span>
                            </div>
                            <div className="card-site-anime__text is-text-anime">
                                <span>{item.value}</span>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </SwiperLib>

            <style jsx>{`
        // Add the relevant styles here
      `}</style>
        </div>
    );
};

export default Swiper;
