import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Swiper from './Swiper';
import Icon from './Icon';
import BookingButton from './BookingButton';
import Button from './Button';
import Location from './Location';
import Property from './Property';
import beach from './beach.mp4';
import { useBreakpointContext } from './App';
import { fetchProperties, mediaPrefix } from './api/apiService';

const CoverSection = ({ block }) => {
    const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
    const [hover, setHover] = useState(false);
    const mainContainerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(null);
    const [showBookingOverlay, setShowBookingOverlay] = useState(false);
    const navigate = useNavigate();
    const [properties, setProperties] = useState([]);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [imagesToLoad, setImagesToLoad] = useState(0);

    const breakpoint = useBreakpointContext();
    const isMobile = breakpoint.isMobile;

    const movingGradientStyle = {
        height: '100%',
        width: '100%',
        background: 'linear-gradient(45deg, transparent 25%, rgba(255, 255, 255, 0.4) 50%, transparent 75%)',
        backgroundColor: 'rgba(0,0,0,0.2)',
        animation: 'gradient 5s linear infinite',
        backgroundSize: '200% 100%',
        WebkitAnimation: 'gradient 3s linear infinite', // for Safari
        filter: 'brightness(108%) contrast(105%) saturate(105%)',
    };

    /*useEffect(() => {
        fetchProperties({ isPreview: true, language: 'en' })
            .then(fetchedProperties => {
                setProperties(fetchedProperties.map(p => ({ ...p, currentImageIndex: 0 }))); // Initialize currentImageIndex for each property
                setIsContentLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch properties:", error);
            });
    }, []);*/

    useEffect(() => {
        setProperties(block.selectedProperties.map(p => ({ ...p, currentImageIndex: 0 })));
    }, []);

    useEffect(() => {
        const updateContainerWidth = () => {
            if (mainContainerRef.current) {
                setContainerWidth(mainContainerRef.current.clientWidth);
            }
        };

        updateContainerWidth();

        const resizeObserver = new ResizeObserver(updateContainerWidth);
        if (mainContainerRef.current) {
            resizeObserver.observe(mainContainerRef.current);
        }

        window.addEventListener('resize', updateContainerWidth);

        return () => {
            if (mainContainerRef.current) {
                resizeObserver.unobserve(mainContainerRef.current);
            }
            window.removeEventListener('resize', updateContainerWidth);
        };
    }, []);

    const handlePropertyClick = () => {
        navigate(`/properties/${properties[currentPropertyIndex].slug}`);
    };

    const handleThumbnailClick = (property, index) => {
        const newProperties = properties.map((p) => {
            if (p.id === property.id) {
                return {
                    ...p,
                    currentImageIndex: index,
                };
            }
            return p;
        });
        setProperties(newProperties);
    };

    const handleArrowClick = (direction) => {
        const prevIndex = currentPropertyIndex;
        const newIndex = (prevIndex + direction + properties.length) % properties.length; // Modulo to handle negative values
        setCurrentPropertyIndex(newIndex);

        // Update the current image index for the property
        setProperties((prevProperties) => {
            const currentProperty = prevProperties[prevIndex];
            const nextImageIndex = (currentProperty.currentImageIndex + 1) % currentProperty.covers.length;
            return prevProperties.map((p, i) => {
                if (i === prevIndex) {
                    return {
                        ...p,
                        currentImageIndex: nextImageIndex,
                    };
                }
                return p;
            });
        });
    };

    useEffect(() => {
        if (!hover && !showBookingOverlay) {
            const timer = setTimeout(() => {
                setCurrentPropertyIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % properties.length;
                    const currentProperty = properties[newIndex];
                    const nextImageIndex = (currentProperty.currentImageIndex + 1) % currentProperty.covers.length;

                    // Update the current image index for the property
                    setProperties((prevProperties) => {
                        return prevProperties.map((p, i) => {
                            if (i === newIndex) {
                                return {
                                    ...p,
                                    currentImageIndex: nextImageIndex,
                                };
                            }
                            return p;
                        });
                    });

                    return newIndex;
                });
            }, 5000); // Change the property every 5 seconds

            return () => clearTimeout(timer);
        }
    }, [hover, currentPropertyIndex, showBookingOverlay, properties]);

    const currentProperty = properties[currentPropertyIndex];
    const currentImage = currentProperty ? currentProperty.covers[currentProperty.currentImageIndex] : '';

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                height: isMobile ? `calc(90vh - 63px)` : '72vh',
                padding: '0px',
                marginLeft: '0px',
                marginRight: '0px',
                marginLeft: isMobile ? 0 : '40px',
                marginRight: isMobile ? 0 : '40px',
                borderRadius: isMobile ? '0px' : '15px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.5s ease-out',
                width: isMobile ? '100%' : 'auto',
            }}
        >
            {
                isContentLoading ? (
                    <>
                        <style>
                            {`
                                @keyframes gradient {
                                0% {
                                    background-position: 200% 0;
                                }
                                100% {
                                    background-position: -200% 0;
                                }
                                }
                            `}
                        </style>
                        <div style={movingGradientStyle}></div>
                    </>

                ) : (
                    <>
                        <AnimatePresence>
                            <motion.div
                                key={currentProperty ? currentProperty.id + '-' + currentProperty.currentImageIndex : ''}
                                initial={{ opacity: 0, scale: 1 }}
                                animate={{ opacity: 1, scale: hover ? 1.1 : 1 }}
                                exit={{ opacity: 0, scale: 1 }}
                                transition={{ duration: 0.7 }}
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    backgroundImage: `url(${mediaPrefix() + currentImage?.cover?.url})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    transition: 'margin 0.5s ease-out, box-shadow 0.5s ease-out',
                                    boxShadow: isMobile ? 'unset' : 'inset 40px 40px 500px 40px rgba(0, 0, 0, .4)',
                                    filter: 'brightness(108%) contrast(105%) saturate(105%)',

                                }}
                            />
                        </AnimatePresence>

                        <div style={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: isMobile ? 'flex-start' : 'flex-start',
                            justifyContent: isMobile ? 'flex-start' : 'flex-start',
                            flexDirection: 'column',
                            height: 'auto',
                            width: isMobile ? 'auto' : 'auto',
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            backdropFilter: 'blur(20px)',
                            WebkitBackdropFilter: 'blur(20px)',
                            left: isMobile ? '0' : '20px',
                            right: isMobile ? 0 : 'auto',
                            bottom: isMobile ? 0 : '20px',
                            borderRadius: isMobile ? 0 : '15px',
                            padding: isMobile ? '15px' : '25px',
                            transition: 'all 0.5s ease',
                            zIndex: '100',
                            gap: isMobile ? '5px' : '15px'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'left',
                                flexDirection: 'row',
                                fontSize: isMobile ? '1.7em' : '2.3em',
                                fontFamily: 'Anuphan',
                                fontWeight: 500,
                                color: 'rgb(252, 234, 198)',
                            }}>
                                {currentProperty ? currentProperty.name : ''}
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: isMobile ? 'flex-start' : 'flex-start',
                                flexDirection: 'row',
                                fontSize: isMobile ? '1em' : '1.15em',
                                fontFamily: 'Anuphan',
                                textAlign: isMobile ? 'left' : 'left',
                                marginBottom: '10px',
                                maxWidth: isMobile ? 'auto' : '400px',
                                color: 'white',
                            }}>
                                {currentProperty ? currentProperty.tagline : ''}
                            </div>
                            <div
                                style={{
                                    borderRadius: breakpoint === 'lg' || breakpoint === 'xl' ? '10px' : '0px',
                                    display: 'flex',
                                    alignItems: 'stretch',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    fontWeight: 'bold',
                                    color: '#FFF9ED',
                                    paddingTop: '0px',
                                    //background: 'rgba(0, 0, 0, 0.5)',
                                    opacity: 1,
                                    zIndex: '100',
                                    transition: 'opacity 0.5s ease',
                                    fontSize: '1.6em',
                                    //backdropFilter: 'blur(20px)',
                                    //WebkitBackdropFilter: 'blur(20px)',
                                    fontFamily: 'Anuphan',
                                    fontWeight: '500',
                                    marginTop: '-10px',
                                    width: breakpoint === 'lg' || breakpoint === 'xl' ? 'auto' : '100%',
                                }}
                            >

                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'row',
                                        bottom: 0,
                                        marginLeft: isMobile ? 0 : '-7px',
                                        borderBottomRightRadius: breakpoint === 'lg' || breakpoint === 'xl' ? '10px' : '0px',
                                        borderBottomLeftRadius: breakpoint === 'lg' || breakpoint === 'xl' ? '10px' : '0px',
                                    }}
                                >
                                    {currentProperty ? currentProperty.covers.map((image, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: currentProperty.currentImageIndex === index ? '30px' : '30px',
                                                height: currentProperty.currentImageIndex === index ? '30px' : '30px',
                                                backgroundImage: `url(${mediaPrefix() + image.cover.url})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                margin: '5px',
                                                borderRadius: '5px',
                                                border: currentProperty.currentImageIndex === index ? '2px solid white' : 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleThumbnailClick(currentProperty, index)}
                                        />
                                    )) : null}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            height: '100%',
                            width: '100%',
                        }}>
                            <div
                                onClick={() => handleArrowClick(-1)}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    height: '120px',
                                    fontSize: '2em',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: isMobile ? 'transparent' : '#fdfaf3',
                                    transition: 'opacity 0.5s ease',
                                    borderRadius: '0px 10px 10px 0px',
                                    paddingRight: '12px',
                                    paddingLeft: '0px',
                                    marginLeft: hover ? '0px' : '-40px',
                                    transition: 'margin-left 0.1s ease-in-out, padding-left 0.2s ease-in-out, padding-right 0.2s ease-in-out',
                                    left: isMobile ? '20px' : '0px',
                                }}
                            >
                                <Icon name="arrowLeft" width={isMobile ? '20px' : '12px'} color={isMobile ? '#ffffff' : '#072F5F'} />
                            </div>

                            <div
                                onClick={() => handleArrowClick(1)}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    height: '120px',
                                    fontSize: '2em',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: isMobile ? 'transparent' : '#fdfaf3',
                                    transition: 'opacity 0.5s ease',
                                    borderRadius: '10px 0px 0px 10px',
                                    paddingRight: '0px',
                                    paddingLeft: '12px',
                                    marginRight: hover ? '0px' : '-40px',
                                    transition: 'margin-right 0.1s ease-in-out, padding-left 0.2s ease-in-out, padding-right 0.2s ease-in-out',
                                    right: isMobile ? '20px' : '0px',
                                }}
                            >
                                <Icon name="arrowRight" width={isMobile ? '20px' : '12px'} color={isMobile ? '#ffffff' : '#072F5F'} />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    right: '0px',
                                    zIndex: 100,
                                    opacity: 1,
                                    transition: 'opacity 0.5s ease',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <Location location={currentProperty?.location?.symbolic} />
                            </div>
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            marginTop: isMobile ? '30px' : '20px',
                            right: isMobile ? '15px' : '20px',
                            left: isMobile ? '15px' : '20px',
                            opacity: 1,
                            transition: 'opacity 0.5s ease',
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: isMobile ? 'space-between' : 'flex-end',
                            flexDirection: 'row',
                            gap: '10px',
                        }}>
                            <div onClick={handlePropertyClick}>
                                <Button />
                            </div>
                            <BookingButton
                                property={currentProperty ? currentProperty : null}
                                available={currentProperty ? currentProperty.availability === "Available" : false}
                                nativeBooking={currentProperty ? currentProperty.nativeBooking : false}
                                propertyId={currentProperty ? currentProperty.hospitableId : ''}
                                propertyTitle={currentProperty ? currentProperty.name : ''}
                                showBookingOverlay={showBookingOverlay}
                                setShowBookingOverlay={setShowBookingOverlay}
                            />
                        </div>
                    </>
                )
            }

        </div>
    );
};

export default CoverSection;
