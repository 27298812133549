import React from 'react';
import { mediaPrefix } from './api/apiService';

const LinkTree = ({ block }) => {
    const { linkGroups, title, description, property } = block;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingRight: '50px',
            paddingLeft: '20px',
        }}>
            {property && (
                <img
                    src={`${mediaPrefix() + property?.originalLogo?.url}`}
                    alt={`${property?.name}`}
                    style={{
                        width: '150px',
                    }}
                />
            )}
            <h1>{title}</h1>
            <p style={{
                width: '100%',
                textAlign: 'center',
            }}>{description}</p>
            {linkGroups.map((group, groupIndex) => (
                <div key={groupIndex} style={{ width: '100%' }}>
                    {group.title && <h2 style={{ margin: '10px 0', fontSize: '14px' }}>{group.title}</h2>}
                    {group.links.map((link, linkIndex) => (
                        <a
                            key={linkIndex}
                            href={link.url}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                backgroundColor: '#ffffff',
                                color: '#333',
                                textDecoration: 'none',
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                margin: '10px 0',
                                border: '1px solid #efefef',
                                transition: 'transform 0.2s ease, background-color 0.2s ease',
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            onMouseEnter={e => {
                                e.currentTarget.style.transform = 'scale(1.01)';
                            }}
                            onMouseLeave={e => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}
                            onTouchStart={e => {
                                e.currentTarget.style.backgroundColor = '#efefef';
                            }}
                            onTouchEnd={e => {
                                e.currentTarget.style.backgroundColor = '#ffffff';
                            }}
                        >
                            {link.icon && (
                                <img
                                    src={`${mediaPrefix() + link.icon.url}`}
                                    alt={`${link.title} icon`}
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        marginRight: '20px',
                                    }}
                                />
                            )}
                            <span>{link.title}</span>
                        </a>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default LinkTree;
