import React, { useState, useEffect, useRef } from 'react';

// Timeline component to show progress and allow interaction
const Timeline = ({ clips, currentClipIndex, setCurrentClipIndex, videoRef }) => {
    const handleClick = (index) => {
        setCurrentClipIndex(index);
        videoRef.current.currentTime = 0;
        videoRef.current.play();
    };

    return (
        <div style={{ position: 'absolute', bottom: '20px', display: 'flex', justifyContent: 'center', width: '100%' }}>
            {clips.map((clip, index) => (
                <div
                    key={index}
                    onClick={() => handleClick(index)}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <div
                        style={{
                            height: '8px',
                            width: '30px',
                            marginRight: '4px',
                            borderRadius: '50px',
                            border: '1px solid white',
                            backgroundColor: index === currentClipIndex ? 'white' : 'rgba(255,255,255,0.5)',
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

// VideoPlayer component
const VideoPlayer = ({ clips }) => {
    const [currentClipIndex, setCurrentClipIndex] = useState(0);
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        video.style.opacity = 0; // set opacity to 0 to start the fade out effect

        setTimeout(() => {
            video.src = clips[currentClipIndex].src; // switch source after 500ms
            video.style.opacity = 1; // set opacity back to 1 for fade in effect
        }, 500);

        video.onended = () => {
            setCurrentClipIndex((prevIndex) => (prevIndex + 1) % clips.length);
        };
    }, [currentClipIndex, clips]);

    return (
        <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
            <video ref={videoRef} controls={false} playsInline autoPlay muted style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'opacity 0.5s' }} />
            <Timeline clips={clips} currentClipIndex={currentClipIndex} setCurrentClipIndex={setCurrentClipIndex} videoRef={videoRef} />
        </div>
    );
};

export default VideoPlayer;