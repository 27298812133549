import React, { useState } from 'react';
import Booking from './Booking';

const BookingButton = ({ property, available, nativeBooking, propertyId, propertyTitle, showBookingOverlay, setShowBookingOverlay }) => {

    return (
        <>
            <button
                onClick={() => available ? setShowBookingOverlay(!showBookingOverlay) : null}
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgb(0, 94, 161, 1)',
                    filter: !available ? 'grayscale(100%)' : 'none',
                    opacity: !available ? 0.8 : 1,
                    color: '#FCEAC6',
                    borderRadius: '10px',
                    padding: '15px 20px',
                    fontSize: '0.9em',
                    border: 'none',
                    cursor: 'pointer',
                    outline: 'none',
                    transition: 'background-color 0.3s ease',
                    fontFamily: 'Anuphan',
                    fontWeight: 500,
                    backdropFilter: 'blur(10px)',
                    gap: '10px',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056B3')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgb(0, 94, 161, 1)')}
            >
                {available ? (
                    <div>Book Property</div>
                ) : (
                    <div>Currently Unavailable</div>
                )}
            </button>
            {showBookingOverlay && (
                <Booking
                    property={property}
                    showBookingOverlay={showBookingOverlay}
                    onClose={() => setShowBookingOverlay(false)}
                    propertyId={propertyId}
                    propertyTitle={propertyTitle}
                    nativeBooking={nativeBooking}
                />
            )}
        </>
    );
};

export default BookingButton;